<template>
  <div>
    <h1>This is page Doc</h1>
  </div>
</template>
<script>
export default {
  name: 'Doc',
}
</script>
